.promo-banner {
  background-color: #d0ecd0;
  color: #0b5a4e;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 10%; // Default margins for other pages
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  // Modifier class for full-width usage
  &--full-width {
    width: 100%;
    margin: 0 0 1rem 0; // Only bottom margin
    border-radius: 10px; // Match hero border-radius
  }

  @media (max-width: 768px) {
    margin: 15px 5%;
    padding: 12px 15px;

    &--full-width {
      margin: 0 0 1rem 0;
    }
  }

  .promo-content {
    text-align: center;
    margin-bottom: 10px;

    h2 {
      margin: 0;
      font-size: 20px;
      font-weight: bold;
      color: #0b5a4e;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }

    p {
      margin: 5px 0 0;
      font-size: 14px;
      color: #0b5a4e;

      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
  }

  .offer-indicators {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    .indicator {
      width: 8px;
      height: 8px;
      background-color: rgba(11, 90, 78, 0.3);
      border-radius: 50%;
      margin: 0 4px;
      transition: background-color 0.3s ease;
      cursor: pointer;

      &.active {
        background-color: #0b5a4e;
      }
    }
  }

  .animated-text {
    animation: fadeInUp 0.5s ease-out;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
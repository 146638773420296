.badges-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 3rem 0;
    position: relative;
    color: white;
  
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 10%;
      right: 10%;
      height: 2px;
      background: #e0e0e0;
      z-index: 0;
    }
  
    .badge {
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
      width: 13rem;
      background-color: #0b5a4e;
      color: white;
  
      .badge-circle {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: #fff;
        border: 2px solid #0b5a4e;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        transition: transform 0.3s ease;
        box-shadow: 0 4px 8px rgba(11, 90, 78, 0.1);
        &:hover {
          transform: scale(1.1);
        }
  
        .emoji {
          font-size: 2rem;
        }
      }
  
      .badge-content {
        text-align: center;
        font-size: 0.7;
        margin: 0;
        line-height: 1.4;
      }
    }
  }
  
  @media (max-width: 768px) {
    .badges-container {
      gap: 1.5rem;
      overflow-x: auto;
      flex-wrap: nowrap;
      padding: 1rem 0;
      justify-content: flex-start;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      
      &::before {
        display: none;
      }
  
      .badge {
        width: 120px;
        flex-shrink: 0;
  
        .badge-circle {
          width: 60px;
          height: 60px;
  
          .emoji {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
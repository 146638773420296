.hero-banner {
  display: flex;
  background-color: #0b5a4e;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 10% 20px;
  color: #ffffff;
  min-height: 500px;

  @media (max-width: 768px) {
      flex-direction: column;
      margin: 0 5% 20px;
      min-height: auto;
  }

  .hero-content {
      flex: 1;
      padding: 80px 60px;

      @media (max-width: 768px) {
          padding: 40px 30px;
      }

      h1 {
          font-size: 36px;
          margin: 0 0 10px 0;
  
          @media (max-width: 768px) {
              font-size: 28px;
          }
      }

      h2 {
          font-size: 24px;
          margin: 0 0 20px 0;
  
          @media (max-width: 768px) {
              font-size: 20px;
          }
      }

      p {
          font-size: 18px;
          margin-bottom: 30px;
          opacity: 0.9;
  
          @media (max-width: 768px) {
              font-size: 16px;
          }
      }

      // New button container for better mobile layout
      .button-container {
          display: flex;
          gap: 15px; // Consistent spacing between buttons
          
          @media (max-width: 768px) {
              flex-direction: column;
              gap: 12px; // Slightly reduced spacing for mobile
          }
      }

      .cta-button, .secondary-button {
          padding: 12px 24px;
          border-radius: 5px;
          font-weight: bold;
          cursor: pointer;
          transition: all 0.3s ease;
          width: fit-content; // Ensure buttons only take necessary width
  
          @media (max-width: 768px) {
              padding: 12px 24px;
              font-size: 16px;
              width: 100%; // Full width on mobile
              margin-right: 0; // Remove margin as we're using gap
          }
      }

      .cta-button {
          background-color: #ffffff;
          color: #0b5a4e;
          border: none;
  
          &:hover {
              background-color: #f0f0f0;
          }
      }

      .secondary-button {
          background-color: transparent;
          border: 2px solid #ffffff;
          color: #ffffff;
  
          &:hover {
              background-color: rgba(255, 255, 255, 0.1);
          }
      }
  }

  .hero-image {
      flex: 1;
      position: relative;
      overflow: hidden;
  
      @media (max-width: 768px) {
          height: 350px;
      }

      img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 0;
          transition: opacity 0.5s ease-in-out;
  
          &.active {
              opacity: 1;
          }
      }
  }
}
.dental-chatbot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  
    .chat-icon {
      background-color: #0b5a4e;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: none;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  
    .rsc-float-button {
      @extend .chat-icon;
      padding: 0;
    }
  
    .rsc-container {
      position: absolute;
      bottom: 80px;
      right: 0;
    }
  }
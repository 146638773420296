.captcha-container {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  max-width: 100%;
  margin: 0 auto;
  font-family: Arial, sans-serif;

  .captcha-row {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .captcha-text {
    background-color: #e0e0e0;
    padding: 5px 10px;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 3px;
    text-align: center;
    user-select: none;
    font-family: 'Courier New', monospace;
    color: #333;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    flex-shrink: 0;

    &::before {
      content: '';
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      background: repeating-linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.05),
        rgba(0, 0, 0, 0.05) 5px,
        rgba(0, 0, 0, 0) 5px,
        rgba(0, 0, 0, 0) 10px
      );
    }
  }

  .captcha-input {
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 3px;
    width: 60px;
    text-align: center;

    &:focus {
      outline: none;
      border-color: #0b5a4e;
      box-shadow: 0 0 0 2px rgba(11, 90, 78, 0.1);
    }
  }

  .captcha-submit,
  .captcha-refresh {
    background-color: #0b5a4e;
    color: white;
    border: none;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: darken(#0b5a4e, 10%);
    }
  }

  .captcha-refresh {
    padding: 5px 8px;
    background-color: #4a4a4a;

    &:hover {
      background-color: darken(#4a4a4a, 10%);
    }
  }

  .captcha-message {
    margin-top: 5px;
    padding: 3px;
    border-radius: 3px;
    text-align: center;
    font-size: 12px;

    &.valid {
      background-color: #d4edda;
      color: #155724;
    }

    &.invalid {
      background-color: #f8d7da;
      color: #721c24;
    }
  }
}
// Navbar.scss
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-family: "Noto Sans", sans-serif;
  font-weight: 800;
  font-style: bold;

  .navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 5%;
  }

  .logo {
    font-size: 24px;
    font-weight: bold;
    color: #0b5a4e;
  }

  .hamburger {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 1002;

    @media (max-width: 768px) {
      display: block;
    }

    span {
      display: block;
      width: 25px;
      height: 3px;
      background-color: #0b5a4e;
      margin: 5px 0;
      transition: all 0.3s ease;
    }
  }

  .close-button {
    display: none;
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1002;
    padding: 10px;

    @media (max-width: 768px) {
      &.visible {
        display: block;
      }
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 25px;
      height: 3px;
      background-color: #0b5a4e;
      transition: all 0.3s ease;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  .nav-links {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      position: fixed;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100vh;
      background-color: #ffffff;
      flex-direction: column;
      justify-content: center;
      transition: left 0.3s ease;
      z-index: 1001;

      &.open {
        left: 0;
      }
    }

    ul {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }

      li {
        margin: 0 15px;

        @media (max-width: 768px) {
          margin: 15px 0;
        }

        a {
          text-decoration: none;
          color: #333333;
          font-size: 16px;
          transition: color 0.3s ease;

          &:hover {
            color: #0b5a4e;
          }
        }
      }
    }
  }

  .appointment-btn {
    background-color: #0b5a4e;
    color: #ffffff;
    border: none;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    @media (max-width: 768px) {
      margin-top: 20px;
    }

    &:hover {
      background-color: #094940;
    }
  }

  &.menu-open {
    .hamburger {
      display: none;
    }

    .nav-links {
      left: 0;
      font-size: 16px;
    }

    .logo {
      opacity: 0;
    }
  }
}
// pages/HomePage/HomePage.scss
.home-page {
  background-image: url('../../assets/images/image.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  .content {
    margin: 40px 10% 0;


    @media (max-width: 768px) {
      margin: 30px 5% 0;
    }

    section {
      margin-bottom: 60px;

      h2 {
        font-size: 28px;
        color: #0b5a4e;
        margin-bottom: 20px;
      }
    }

    .services, .best-services, .reviews {
      display: flex;
      flex-direction: column;

      .servicesHeading{
        display: flex;
        align-self: center;
        align-items: center;
      }
      // Add specific styles for each section if needed
    }

    .whyheading{
      align-self: center;
    }

    .best-services {
      // Ensure the BestServices component is visible
      display: block;
      width: 100%;
      .servicesHeading{
        align-self: center;
      }

    }
  }
}
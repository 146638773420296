$theme-color: #0b5a4e;  // User's theme color
$background-light: #e0f2f1; // Lighter version for background

.review-cards-container {
  width: 100%;
  overflow: hidden;
  padding: 20px 0;
  background-color: #f0f7f0;
}

.review-cards-scroller {
  display: flex;
  flex-wrap: nowrap;
  animation: scroll 20s linear infinite;

  &:hover {
    animation-play-state: paused;
  }
}

.review-card {
  min-width: 320px;
  margin: 0 20px;
  padding: 30px;  // Increased padding
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  .review-stars {
    margin-bottom: 10px;

    .star {
      font-size: 20px;
      color: #ffb400;
    }
  }

  .review-text {
    font-size: 16px;
    font-weight: 500;
    color: $theme-color;  // Using theme color for text
    line-height: 1.4;
    margin-bottom: 10px;
  }

  .review-author {
    font-size: 16px;
    color: #555;
    font-style: italic;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

// Media queries for mobile responsiveness
@media (max-width: 768px) {
  .review-card {
    min-width: 250px;
    padding: 20px;  // Adjust padding for smaller screens

    .review-text {
      font-size: 16px;
    }
  }
}

// LocationComponent.scss
.location-container {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    gap: 24px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
    }
}

.address-section {
    flex: 1;
    padding: 20px;

    h2 {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 24px;
        color: #333;
    }

    .info-block {
        margin-bottom: 24px;

        h3 {
            font-size: 16px;
            font-weight: 600;
            color: #4b5563;
            margin-bottom: 8px;
            text-transform: uppercase;
            letter-spacing: 0.05em;
        }
    }

    address {
        font-style: normal;
        line-height: 1.6;
        color: #666;
        font-size: 16px;
    }

    .phone {
        color: #666;
        font-size: 16px;
        line-height: 1.6;
    }

    .hours-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .hours-item {
        display: flex;
        justify-content: space-between;
        color: #666;
        font-size: 16px;

        .days {
            font-weight: 500;
        }

        .time {
            color: #4b5563;
        }
    }
}

.map-section {
    flex: 0.8;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.map-container {
    width: 100%;
    height: 250px;
    border-radius: 8px;
    overflow: hidden;
}

.directions-button {
    padding: 12px 24px;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
        background-color: #1d4ed8;
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.2);
    }
}
.header {
    width: 100%;
    height: 30vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
  
    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6));
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    &__title-container {
      position: relative;
      display: flex;
      align-items: center;
      width: fit-content;
    }
  
    &__cursor {
      position: absolute;
      width: 3px;
      height: 3rem;
      background-color: #ffffff;
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    }
  
    &__title {
      color: #ffffff;
      font-size: 3rem;
      font-weight: 700;
      text-align: left;
      margin: 0;
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      text-shadow: 
        2px 2px 4px rgba(0, 0, 0, 0.7),
        -1px -1px 0 rgba(0, 0, 0, 0.5),
        1px -1px 0 rgba(0, 0, 0, 0.5),
        -1px 1px 0 rgba(0, 0, 0, 0.5),
        1px 1px 0 rgba(0, 0, 0, 0.5);
      letter-spacing: 1px;
    }
  }
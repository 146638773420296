.services-container {
  padding: 30px;
  max-width: 1100px;
  margin: 0 auto;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);

  .services-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }

  .services-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr); // Changed to 6 columns
    gap: 12px; // Reduced gap for more cards
    justify-items: center;
    width: 100%;
    overflow: visible;
    justify-content: center;
    padding: 5px;
  }

  .service-card {
    width: 100%;
    max-width: 170px; // Reduced max-width to fit 6 cards
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #0b5a4e;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    text-align: center;
    background-color: #ffffff;
    transition: transform 0.2s, box-shadow 0.2s, border-color 0.2s;
    box-sizing: border-box;
    margin: 0 auto;
    position: relative;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
      border-color: #0b5a4e;
      z-index: 1;
    }

    &:first-child {
      transform-origin: left center;
    }

    &:last-child {
      transform-origin: right center;
    }

    .service-logo {
      width: 55px; // Reduced size
      height: 55px; // Reduced size
      margin-bottom: 8px;
    }

    .service-name {
      color: #0b5a4e;
      margin-bottom: 8px;
      font-size: 15px; // Reduced font size
      font-weight: 700;
    }

    .service-description {
      color: #666;
      font-size: 12px; // Reduced font size
    }
  }
}

// For larger tablets (1024px and below)
@media (max-width: 1024px) {
  .services-container {
    margin: 20px;
    
    .services-grid {
      grid-template-columns: repeat(4, 1fr); // Show 4 cards per row

      .service-card {
        max-width: 190px; // Slightly larger cards for 4-column layout
      }
    }
  }
}

// For medium devices (768px and below)
@media (max-width: 768px) {
  .services-container {
    margin: 15px;
    
    .services-grid {
      grid-template-columns: repeat(3, 1fr); // Show 3 cards per row

      .service-card {
        max-width: 200px; // Slightly larger cards for 3-column layout
      }
    }
  }
}

// For mobile devices (480px and below)
@media (max-width: 480px) {
  .services-container {
    padding: 20px;
    width: calc(100% - 30px);
    margin: 15px;
    
    .services-grid {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100%;
      align-items: center;
      padding: 0;
    }
    
    .service-card {
      width: 100%;
      max-width: 85%;
      margin: 0;
      
      .service-logo {
        width: 60px; // Slightly larger for mobile
        height: 60px;
      }

      .service-name {
        font-size: 16px; // Slightly larger for mobile
      }

      .service-description {
        font-size: 13px; // Slightly larger for mobile
      }
      
      &:hover {
        transform: none;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
        border-color: #0b5a4e;
      }
    }
  }
}
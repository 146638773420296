.appointment-form-overlay {
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  overflow-y: auto;

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

.appointment-form {
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 400px;
  position: relative;
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 0.3s ease, transform 0.3s ease;
  margin: 1rem auto;

  &.visible {
    opacity: 1;
    transform: scale(1);
  }

  h2 {
    margin-bottom: 1rem;
    color: #0b5a4e;
    font-size: 1.3rem;
    text-align: center;
    padding: 0 1rem;
  }

  .form-group {
    margin-bottom: 0.75rem;
    padding: 0 0.5rem;

    label {
      display: block;
      margin-bottom: 0.25rem;
      font-weight: 600;
      color: #333;
      font-size: 0.9rem;
    }

    .error-message {
      display: block;
      color: #dc3545;
      font-size: 0.75rem;
      margin-top: 0.25rem;
      font-weight: 400;
    }

    input.error {
      border-color: #dc3545;
      
      &:focus {
        box-shadow: 0 0 0 2px rgba(220, 53, 69, 0.1);
      }
    }

    .radio-group {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      padding: 0.25rem 0;

      label {
        display: flex;
        align-items: center;
        margin-right: 0.5rem;
        margin-bottom: 0.25rem;
        cursor: pointer;

        input[type="radio"] {
          margin-right: 0.25rem;
        }
      }
    }

    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="date"],
    textarea {
      width: 100%;
      padding: 0.5rem 0.75rem;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 0.9rem;
      box-sizing: border-box;

      &:focus {
        outline: none;
        border-color: #0b5a4e;
        box-shadow: 0 0 0 2px rgba(11, 90, 78, 0.1);
      }
    }

    textarea {
      resize: vertical;
      min-height: 30px;
      height: 40px;
      padding: 0.5rem 0.75rem;
    }

    &.checkbox-group {
      margin-bottom: 1rem;
      padding: 0 0.5rem;

      label {
        display: flex;
        align-items: flex-start;
        font-weight: normal;
        font-size: 0.8rem;

        input[type="checkbox"] {
          margin-right: 0.25rem;
          margin-top: 0.1rem;
        }
      }
    }
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(240, 240, 240, 0.9);
    border: none;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    padding: 0;

    &:hover {
      background: #e0e0e0;
    }

    &::before {
      content: '×';
      color: #333;
      font-size: 24px;
      line-height: 1;
      font-family: Arial, sans-serif;
    }

    &:hover::before {
      color: #000;
    }
  }

  .submit-btn {
    width: calc(100% - 1rem);
    margin: 0 0.5rem;
    padding: 0.75rem;
    background-color: #0b5a4e;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    font-weight: 600;
    cursor: pointer;

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }

  .success-message {
    color: #0b5a4e;
    font-weight: 600;
    text-align: center;
    font-size: 0.9rem;
    padding: 0;
    margin: 1rem auto;
    width: calc(100% - 2rem);
    max-width: 100%;
    box-sizing: border-box;
  }
}

.notification {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #0b5a4e;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 600;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  opacity: 0;
  z-index: 1001;
  animation: fadeInOut 3s ease-in-out forwards;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
    transform: translate(-50%, 20px);
  }
  10%, 90% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
}

@media screen and (max-width: 480px) {
  .appointment-form {
    padding: 1rem;
    width: 92%;
    max-width: none;
    margin: 0.5rem auto;

    h2 {
      font-size: 1.2rem;
      padding-right: 30px;
    }

    .success-message {
      width: calc(100% - 1rem);
      margin: 0.5rem auto;
      padding: 0;
      font-size: 0.85rem;
    }

    .form-group {
      padding: 0 0.75rem;

      label {
        font-size: 0.85rem;
      }

      .error-message {
        font-size: 0.7rem;
      }

      input[type="text"],
      input[type="email"],
      input[type="tel"],
      input[type="date"],
      textarea {
        font-size: 0.85rem;
        padding: 0.6rem 0.75rem;
      }
    }

    .submit-btn {
      width: calc(100% - 1.5rem);
      margin: 0 0.75rem;
      font-size: 0.85rem;
    }

    .close-btn {
      top: 8px;
      right: 8px;
      width: 24px;
      height: 24px;
      background: rgba(240, 240, 240, 0.95);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

      &::before {
        font-size: 20px;
      }
    }
  }
}
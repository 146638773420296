body{
  background-image: url('../../assets/images/image.png');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
.about-us-container {
  margin: auto 10%;
  padding: 1rem 12%;
  color: #333;
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;  // Set overall font weight to normal (400)
  line-height: 1.5;
  

  h2 {
    color: #0b5a4e;
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom: -1rem;
    font-weight: 500;  // Adjust heading font weight to 500 (normal)
  }

  p {
    font-size: 18px;   // Font size remains at 18px
    font-weight: 400;  // Normal font weight for paragraphs (not bold)
    color: black;
    margin-bottom: 1rem;
    text-align: justify;
  }
}

@media (max-width: 768px) {
  .about-us-container {
    padding: 2rem 5%;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 500;  // Ensure normal font weight on mobile
  }

  p {
    font-size: 16px;   // Font size adjusts for mobile
    font-weight: 400;  // Normal font weight for mobile
  }
}

// service-showcase.scss

$theme-color: #0b5a4e;
$background-color: #f3f4f6;
$text-color: #374151;

.service-showcase {
  position: relative;
  background-color: $background-color;
  padding: 2rem 1rem;
  overflow: hidden;

  &__title {
    font-size: 1.75rem;
    font-weight: 700;
    text-align: center;
    color: $theme-color;
    margin-bottom: 1.5rem;

    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }

  &__content {
    max-width: 36rem;
    margin: 0 auto;
    height: 12rem; // Decreased height
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: 768px) {
      max-width: 48rem;
      height: 14rem; // Decreased height for larger screens
    }
  }

  &__service {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  &__service-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: $theme-color;
    margin-bottom: 0.5rem;

    @media (min-width: 768px) {
      font-size: 1.5rem;
    }
  }

  &__description {
    font-size: 0.875rem;
    color: $text-color;
    line-height: 1.4;
    max-height: 7rem; // Limit description height
    overflow: hidden;

    @media (min-width: 768px) {
      font-size: 1rem;
      max-height: 8rem; // Slightly larger for bigger screens
    }
  }

  &__progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.25rem;
    background-color: $theme-color;
  }
}
// App.scss
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  color: #333333;
  line-height: 1.6;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding-top: 80px; // Adjust this value to match your navbar height
}

// Global styles
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
}

// Add any other global styles here
// Variables
$primary-color: #0b5a4e;
$text-primary: #333333;
$text-secondary: #666666;
$background-light: #f8f9fa;
$border-color: #e9ecef;
$white: #ffffff;
$shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.1);
$shadow-md: 0 4px 8px rgba(0, 0, 0, 0.1);
$shadow-lg: 0 8px 16px rgba(0, 0, 0, 0.2);
$border-radius: 8px;
$spacing-unit: 1rem;

// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin card-hover {
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: translateY(-5px);
    box-shadow: $shadow-lg;
  }
}

@mixin responsive($breakpoint) {
  @if $breakpoint == tablet {
    @media (max-width: 1024px) {
      @content;
    }
  }
  @if $breakpoint == mobile {
    @media (max-width: 768px) {
      @content;
    }
  }
  @if $breakpoint == small {
    @media (max-width: 480px) {
      @content;
    }
  }
}

// Main Container
.service-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: $spacing-unit * 2;
  overflow-x: hidden;
  min-height: calc(100vh - 100px);

  @include responsive(mobile) {
    padding: $spacing-unit;
  }
}

// Service Card
.service-card {
  background-color: $white;
  border-radius: $border-radius;
  box-shadow: $shadow-sm;
  overflow: hidden;
  will-change: transform;
  transition: transform 0.3s ease;
}

.procedure-card {
  background-color: $background-light;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  @include card-hover;
  will-change: transform;
  overflow: hidden;
  padding: 0;
  position: relative;

  .collapsible-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $spacing-unit * 1.5;
  }

  .procedure-title {
    font-size: 1.1rem;
    font-weight: 600;
    color: $primary-color;
    transition: color 0.3s ease;
    margin: 0 0 $spacing-unit 0;
  }

  .procedure-description {
    color: $text-secondary;
    line-height: 1.5;
    font-size: 0.95rem;
    margin-bottom: $spacing-unit;
  }

  .additional-content {
    color: $text-secondary;
    line-height: 1.5;
    font-size: 0.95rem;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.3s ease;

    &.expanded {
      max-height: 500px;
      opacity: 1;
      transform: translateY(0);
      margin-bottom: $spacing-unit;
    }
  }

  .expand-button {
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    .arrow-icon {
      color: $primary-color;
      transition: transform 0.3s ease;
      display: inline-block;
      font-size: 0.8rem;

      &.expanded {
        transform: rotate(180deg);
      }
    }

    &:hover .arrow-icon {
      transform: translateY(2px);

      &.expanded {
        transform: rotate(180deg) translateY(-2px);
      }
    }
  }

  &:hover {
    .procedure-title {
      color: darken($primary-color, 10%);
    }
  }
}

// Header Section
.service-header {
  padding: $spacing-unit * 2;
  background-color: $primary-color;
  color: $white;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: $spacing-unit;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    height: 10px;
    background: linear-gradient(to bottom, rgba(0,0,0,0.1), transparent);
  }

  @include responsive(mobile) {
    padding: $spacing-unit * 1.5;
    flex-direction: column;
    text-align: center;
  }
}

.service-title {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  line-height: 1.2;

  @include responsive(mobile) {
    font-size: 1.5rem;
    margin-bottom: $spacing-unit;
  }
}

// Content Section
.service-content {
  padding: $spacing-unit * 2;

  @include responsive(mobile) {
    padding: $spacing-unit;
  }
}

// Overview Text
.overview-text {
  font-size: 1.1rem;
  line-height: 1.8;
  color: $text-primary;
  margin-bottom: $spacing-unit * 2;
  
  @include responsive(mobile) {
    font-size: 1rem;
    line-height: 1.6;
  }
}

// Sections
.service-section {
  margin-bottom: $spacing-unit * 2.5;

  &:last-child {
    margin-bottom: 0;
  }
}

.section-title {
  font-size: 1.5rem;
  color: $primary-color;
  margin-bottom: $spacing-unit;
  font-weight: 600;
  position: relative;
  padding-bottom: $spacing-unit * 0.5;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 3px;
    background-color: $primary-color;
    border-radius: 2px;
  }

  @include responsive(mobile) {
    font-size: 1.25rem;
  }
}

// Why Choose Section
.why-choose-section {
  background-color: $background-light;
  padding: $spacing-unit * 2;
  border-radius: $border-radius;
  margin: $spacing-unit * 2 0;
  
  .section-title {
    text-align: center;
    margin-bottom: $spacing-unit * 2;
    
    &::after {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.why-choose-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: $spacing-unit * 1.5;
  
  @include responsive(mobile) {
    grid-template-columns: 1fr;
  }
}

.why-choose-card {
  background: $white;
  padding: $spacing-unit * 2;
  border-radius: $border-radius;
  text-align: center;
  box-shadow: $shadow-sm;
  transition: all 0.3s ease;
  border: 1px solid $border-color;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: $shadow-md;
    
    .why-choose-icon {
      transform: scale(1.1);
      background-color: rgba($primary-color, 0.1);
    }
  }
}

.why-choose-icon {
  width: 60px;
  height: 60px;
  background-color: rgba($primary-color, 0.05);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto $spacing-unit;
  transition: all 0.3s ease;
  
  .icon {
    font-size: 1.8rem;
  }
}

.why-choose-title {
  color: $primary-color;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: $spacing-unit;
}

.why-choose-description {
  color: $text-secondary;
  font-size: 0.95rem;
  line-height: 1.6;
  margin: 0;
}

// Procedures Grid
.procedures-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: $spacing-unit;

  @include responsive(mobile) {
    grid-template-columns: 1fr;
  }
}

.procedure-card {
  background-color: $background-light;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  padding: $spacing-unit * 1.5;
  @include card-hover;
  will-change: transform;

  &:hover {
    .procedure-title {
      color: darken($primary-color, 10%);
    }
  }
}

.procedure-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: $spacing-unit * 0.5;
  color: $primary-color;
  transition: color 0.3s ease;
}

.procedure-description {
  color: $text-secondary;
  line-height: 1.5;
  font-size: 0.95rem;
}

// Benefits List
.benefits-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.benefit-item {
  display: flex;
  align-items: center;
  gap: $spacing-unit * 0.5;
  margin-bottom: $spacing-unit * 0.5;
  padding: $spacing-unit * 0.5;
  border-radius: 4px;
  transition: all 0.3s ease;
  cursor: default;

  &:hover {
    background-color: rgba($primary-color, 0.05);
    transform: translateX($spacing-unit * 0.5);
  }

  .bullet {
    color: $primary-color;
    font-size: 1.2rem;
    flex-shrink: 0;
  }
}

// Testimonials
.testimonials-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: $spacing-unit * 1.5;
  
  @include responsive(mobile) {
    grid-template-columns: 1fr;
  }
}

.testimonial-card {
  background-color: $background-light;
  border-radius: $border-radius;
  padding: $spacing-unit * 1.5;
  border: 1px solid $border-color;
  position: relative;
  @include card-hover;
  
  &::before {
    content: '"';
    position: absolute;
    top: -5px;
    left: 10px;
    font-size: 4rem;
    color: rgba($primary-color, 0.1);
    font-family: serif;
  }
}

.testimonial-text {
  font-style: italic;
  color: $text-primary;
  line-height: 1.6;
  margin-bottom: $spacing-unit;
  position: relative;
  z-index: 1;
}

.testimonial-author {
  color: $primary-color;
  font-weight: 600;
  text-align: right;
  margin-top: $spacing-unit * 0.5;
}

// FAQs
.faq-list {
  display: flex;
  flex-direction: column;
  gap: $spacing-unit;
}

.faq-item {
  border-bottom: 1px solid $border-color;
  padding: $spacing-unit;
  border-radius: 4px;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba($primary-color, 0.05);
  }
}

.faq-question {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: $spacing-unit * 0.5;
  color: $primary-color;
}

.faq-answer {
  color: $text-secondary;
  line-height: 1.5;
}

// CTA Button
.cta-button {
  padding: $spacing-unit $spacing-unit * 2;
  font-size: 1rem;
  font-weight: 600;
  border-radius: $border-radius;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  white-space: nowrap;

  &.primary {
    background-color: $white;
    color: $primary-color;
    border: 2px solid $white;

    &:hover {
      background-color: transparent;
      color: $white;
    }
  }

  @include responsive(mobile) {
    padding: $spacing-unit $spacing-unit * 1.5;
    font-size: 0.9rem;
    width: 100%;
  }
}

// CTA Section
.cta-section {
  text-align: center;
  padding: $spacing-unit * 3;
  background-color: $background-light;
  border-radius: $border-radius;
  margin-top: $spacing-unit * 3;
  border: 1px solid $border-color;

  @include responsive(mobile) {
    padding: $spacing-unit * 1.5;
  }

  .cta-button.primary {
    background-color: $primary-color;
    color: $white;
    border: 2px solid $primary-color;

    &:hover {
      background-color: darken($primary-color, 10%);
      border-color: darken($primary-color, 10%);
    }
  }
}

.cta-title {
  font-size: 1.8rem;
  color: $primary-color;
  margin-bottom: $spacing-unit;
  font-weight: 700;

  @include responsive(mobile) {
    font-size: 1.5rem;
  }
}

.cta-description {
  font-size: 1.1rem;
  color: $text-secondary;
  max-width: 600px;
  margin: 0 auto $spacing-unit * 2;
  line-height: 1.6;
}

// Error State
.error-container {
  @include flex-center;
  min-height: 400px;
}

.error-message {
  font-size: 1.25rem;
  color: #dc3545;
  text-align: center;
  padding: $spacing-unit;
  border: 1px solid #dc3545;
  border-radius: $border-radius;
  background-color: rgba(#dc3545, 0.1);
}

// Animation Classes
.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.slide-up {
  opacity: 0;
  transform: translateY(20px);
  animation: slideUp 0.5s ease forwards;
}

// Keyframes
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Print Styles
@media print {
  .service-container {
    padding: 0;
    max-width: 100%;
  }

  .service-card {
    box-shadow: none;
    border: none;
  }

  .procedure-card,
  .benefit-item,
  .faq-item,
  .testimonial-card,
  .why-choose-card {
    break-inside: avoid;
  }
}
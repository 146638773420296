.contact-us {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 100vh;
  margin: 0;
  flex-direction: row;

  .clinic-list {
    width: 40%;
    height: 100vh;
    overflow-y: auto;
    padding: 0;
    margin-top: 2rem;
  }

  .clinic-card {
    background: #fff;
    padding: 1.25rem;
    margin-bottom: 1.25rem;
    margin-right: 1rem;
    border-radius: 0.625rem;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: calc(100% - 1rem);
    box-sizing: border-box;

    &:hover {
      transform: translateY(-0.3125rem);
    }

    &.selected {
      border: 0.125rem solid #0b5a4e;
      box-shadow: 0 0.25rem 0.75rem rgba(0, 90, 78, 0.3);
    }

    .clinic-info {
      h3 {
        color: #0b5a4e;
        margin-bottom: 0.625rem;
      }

      p {
        margin: 0 0 0.625rem;
        color: #555;
      }

      .contact-row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 0.5rem;
      }

      .contact-item {
        display: flex;
        align-items: center;
        margin-right: 1rem;

        .icon {
          font-size: 1rem;
          margin-right: 0.5rem;
        }

        span {
          color: #555;
        }

        .whatsapp-icon {
          color: #25D366;
        }

        .phone-icon {
          color: #34a853;
        }

        .email-icon {
          color: #d93025;
        }
      }

      // New button styles
      .button-row {
        display: flex;
        gap: 1rem;
        margin-top: 1rem;

        button {
          flex: 1;
          padding: 0.75rem;
          border-radius: 0.5rem;
          font-weight: 600;
          cursor: pointer;
          transition: all 0.3s ease;

          &.schedule-button {
            background-color: #0b5a4e;
            color: white;
            border: none;

            &:hover {
              background-color: darken(#0b5a4e, 10%);
            }
          }

          &.details-button {
            background-color: transparent;
            color: #0b5a4e;
            border: 0.125rem solid #0b5a4e;

            &:hover {
              background-color: rgba(11, 90, 78, 0.1);
            }
          }
        }
      }
    }
  }

  .map-container {
    width: 60%;
    height: 100vh;
    margin: 0;
    padding-left: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column-reverse;

    .clinic-list {
      width: 100%;
      height: auto;
      margin-top: 1rem;
    }

    .map-container {
      width: 100%;
      height: 50vh;
    }

    .clinic-card {
      .clinic-info {
        .button-row {
          flex-direction: column;
          gap: 0.5rem;
        }
      }
    }
  }
}
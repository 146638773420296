.page-container {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-image: url('../../assets/images/image.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    overflow-x: hidden;
  }
  
  .location-landing {
    width: 80%;
    margin: 0 auto;
    max-width: 1440px;
    position: relative;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-radius: 20px;
    overflow: hidden;
    z-index: 2;
    margin-top: 2rem;
    margin-bottom: 2rem;
    
    * {
      box-sizing: border-box;
    }
  
    .hero-section {
      position: relative;
      height: 40vh;
      width: 100%;
      margin: 0;
      
      .hero-image-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        background-color: #0b5a4e;
      }
  
      .hero-image {
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        object-fit: cover;
        filter: brightness(0.7);
      }
  
      .hero-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
        padding: 1rem;
  
        h1 {
          color: white;
          font-size: clamp(2rem, 5vw, 3.5rem);
          max-width: 800px;
          text-align: center;
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
          white-space: normal;
          overflow-wrap: break-word;
        }
      }
    }
    
    .welcome-section {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 2rem;
      margin-bottom: -3rem;
    
      .welcome-content {
        flex: 1;
        margin-right: 2rem;
        padding: 2rem;
        align-self: center;
    
        h2 {
          font-size: 2rem;
          color: #0b5a4e;
          margin-bottom: 1rem;
        }
    
        p {
          font-size: 1rem;
          margin-bottom: 1.5rem;
          line-height: 1.6;
          color: #333;
        }
    
        ul {
          list-style: disc inside;
          padding-left: 1rem;
    
          li {
            margin-bottom: 0.5rem;
            font-size: 1rem;
            color: #555;
          }
        }
      }
    
      .welcome-form {
        flex: 1;
        max-width: 500px;
        background: #f9f9f9;
        padding: 2rem;
        border-radius: 10px;
        box-shadow: 0 8px 24px rgba(1, 73, 33, 0.15);
        margin-right: 1rem;
        align-self: center;
        
    
        h2 {
          font-size: 1.8rem;
          color: #0b5a4e;
          margin-bottom: 1rem;
        }
    
        .form-group {
          margin-bottom: 1.5rem;
    
          label {
            display: block;
            font-weight: bold;
            margin-bottom: 0.5rem;
            font-size: 1rem;
            color: #333;
          }
    
          input,
          select,
          textarea {
            width: 100%;
            padding: 0.5rem;
            font-size: 0.8rem;
            border: 1px solid #ccc;
            border-radius: 5px;
            outline: none;
            transition: border-color 0.2s ease-in-out;
    
            &:focus {
              border-color: #0b5a4e;
            }
          }
    
          textarea {
            resize: vertical;
            min-height: 100px;
          }
    
          .error {
            color: red;
            font-size: 0.875rem;
            margin-top: 0.25rem;
          }
        }
    
        .form-group.checkbox-group {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          input[type="checkbox"] {
            width: auto;
          }

          .checkbox-content {
            margin: 0;
          }
        }
    
        button {
          display: inline-block;
          padding: 0.75rem 1.5rem;
          font-size: 1rem;
          color: white;
          background: #0b5a4e;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          transition: background-color 0.2s ease-in-out;
    
          &:hover {
            background: #094c42;
          }
    
          &:disabled {
            background: #ccc;
            cursor: not-allowed;
          }
        }
    
        .success-message {
          font-size: 1rem;
          color: #0b5a4e;
          text-align: center;
          margin-top: 1rem;
        }
      }
    }
    
    @media (max-width: 768px) {
      .welcome-section {
        flex-direction: column;
    
        .welcome-content {
          margin-right: 0;
          margin-bottom: 2rem;
        }
    
        .welcome-form {
          max-width: 100%;
          padding: 1.5rem;
        }
      }
    }
  
    .services-section, .reviews-section {
      padding: 4rem 8%;
      text-align: center;
  
      h2 {
        color: #333;
        font-size: 2.5rem;
        margin-bottom: 3rem;
        text-align: center;
      }
    }
  
    .reviews-section {
      background-color: #f9f9f9;
      padding: 4rem 8%;
      
      .reviews-container {
        display: flex;
        gap: 2rem;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 2rem;
      }
  
      .review-card {
        background: white;
        padding: 2rem;
        border-radius: 12px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        max-width: 400px;
        flex: 1;
        min-width: 280px;
  
        .review-text {
          color: #555;
          font-size: 1.1rem;
          line-height: 1.6;
          margin-bottom: 1rem;
          font-style: italic;
        }
  
        .review-author {
          color: #333;
          font-weight: 600;
          text-align: right;
        }
      }
    }
  }

  .location-section {
    padding: 4rem 8%;
    background-color: #fff;
    margin-bottom: 3rem;

    .location-content {
      display: flex;
      gap: 4rem;
      align-items: flex-start;
      justify-content: space-between;

      .contact-info {
        flex: 1;
        max-width: 500px;

        h2 {
          color: #333;
          font-size: 2.5rem;
          margin-bottom: 2rem;
        }

        .contact-details {
          display: flex;
          flex-direction: column;
          gap: 1.5rem;

          .contact-item {
            display: flex;
            align-items: flex-start;
            gap: 1rem;

            .contact-icon {
              color: #4CAF50;
              min-width: 24px;
              width: 24px;
              height: 24px;
              margin-top: 4px;
            }

            p {
              color: #555;
              font-size: 1.1rem;
              line-height: 1.6;
              margin: 0;
            }
          }
        }
      }

      .map-container {
        flex: 1;
        max-width: 600px;
        height: 400px;
        border-radius: 12px;
        overflow: hidden;
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);

        .map-iframe {
            width: 100%;
            height: 100%;
            border-radius: 12px;
          }
      }
    }
  }
  
  @media (max-width: 768px) {
    .location-landing {
      width: 90%;
  
      .hero-section {
        height: 40vh;
  
        .hero-content h1 {
          font-size: clamp(1.5rem, 4vw, 2.5rem);
        }
      }
  
      .welcome-section {
        flex-direction: column;
        padding: 3rem 5%;
  
        .welcome-content {
          max-width: 100%;
          margin-top: -4rem;
  
          h2 {
            font-size: 2rem;
          }
        }
  
        .welcome-form {
          margin-top: -3rem;
          max-width: 100%;
        }
      }
  
      .reviews-section {
        padding: 3rem 5%;
  
        .review-card {
          min-width: 100%;
        }
      }

      .location-section {
        padding: 2rem 5%;

        .location-content {
          flex-direction: column;
          gap: 2rem;

          .contact-info {
            order: 2;
            max-width: 100%;
            
            h2 {
              font-size: 2rem;
              margin-bottom: 1.5rem;
            }
          }

          .map-container {
            order: 1;
            max-width: 100%;
            height: 300px;
          }
        }
      }
    }
  }

  .badges-container {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;

    .badge {
      background: #f0f8f5;
      padding: 1rem;
      border-radius: 1rem;
      text-align: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      h3 {
        font-size: 1.2rem;
        color: #0b5a4e;
      }

      p {
        font-size: 0.9rem;
      }
    }
  }

  .contact-link {
    color: inherit;
    text-decoration: none;
    transition: color 0.2s ease;
    
    &:hover {
      color: #0b5a4e; // Replace with your brand's primary color
      text-decoration: underline;
    }
  }


// Variables
$primary-color: #0b5a4e;
$primary-light: #fff;
$text-dark: #1a1a1a;
$text-light: #666;
$white: #fff;
$container-width: 1200px;
$border-radius: 8px;
$transition: all 0.3s ease;

// Breakpoints
$breakpoints: (
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px
);

// Responsive Mixin
@mixin respond-below($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}

// Container Mixin
@mixin container {
  width: 80%;
  max-width: $container-width;
  margin: 0 auto;
  
  @include respond-below('lg') {
    width: 90%;
  }
  
  @include respond-below('sm') {
    width: 95%;
  }
}

@mixin section-padding {
  padding: 3rem 0;
  
  @include respond-below('md') {
    padding: 2rem 0;
  }
}

// Keyframe Animations
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(-20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.landing-page {
  margin: 0 10%;
  
  @include respond-below('lg') {
    margin: 0 5%;
  }
  
  @include respond-below('sm') {
    margin: 0 3%;
  }

  .promo {
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;

    :global(.promo-banner) {  // Target the PromoBanner component
      width: 100%;
      max-width: 100%;
      margin: 0;
      padding: 0;
      border-radius: 10px;
      overflow: hidden;
    }
  }
}

// Reset any container styles that might affect the banner
.container {
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.section-name{
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  color: $text-dark;
  position: relative;

}
.section-title {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  color: $text-dark;
  margin-bottom: 2rem;
  
  @include respond-below('md') {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }
  
  @include respond-below('sm') {
    font-size: 1.5rem;
    margin-bottom: 1.25rem;
  }
}

// Custom Button & Card Styles
.custom-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: $border-radius;
  border: none;
  cursor: pointer;
  transition: $transition;
  position: relative;
  overflow: hidden;
  
  @include respond-below('sm') {
    padding: 0.5rem 1.25rem;
    font-size: 0.9rem;
  }
  
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    
    &::after {
      left: 100%;
    }
  }
}

.promo {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;  // Add some space between promo and hero

  // Make sure the PromoBanner component inherits the full width
  & > div {  // This targets the PromoBanner component
    width: 100%;
    border-radius: 10px;  // Match hero border-radius
    overflow: hidden;
  }
}

.hero {
  position: relative;
  background: $primary-color;
  overflow: hidden;
  height: 60vh;
  min-height: 400px;
  border-radius: 10px;
  margin: 0;

  &__overlay {
    display: none;
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0;
    align-items: stretch;
    height: 100%;
    margin: 0;
    padding: 0;
    
    @include respond-below('sm') {
      grid-template-columns: 1fr;
      height: auto;
      display: flex;
      flex-direction: column;
    }
  }

  &__content {
    color: $white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    @include respond-below('sm') {
      text-align: center;
      padding: 1.5rem;
      order: 2; // Moves content below image only on mobile
    }
  }

  &__title {
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 1rem;
    line-height: 1.2;
    
    @include respond-below('sm') {
      font-size: 1.5rem;
    }
  }

  &__subtitle {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    line-height: 1.5;
    opacity: 0.9;
  }

  &__info {
    margin-bottom: 1.5rem;

    @include respond-below('sm') {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 0.5rem;
      margin: 1rem 0;
    }

    .info-item {
      display: flex;
      align-items: center;
      margin-bottom: 0.75rem;
      
      @include respond-below('sm') {
        justify-content: flex-start;
        width: 100%;
        max-width: 300px;
        margin-bottom: 1rem;
        word-break: break-word;
      }
      
      .info-icon {
        width: 18px;
        height: 18px;
        min-width: 18px; // Prevents icon from shrinking
        margin-right: 0.75rem;
        color: rgba($white, 0.9);
      }
      
      span {
        font-size: 0.9rem;
        color: rgba($white, 0.9);
        line-height: 1.4;
        text-align: left; // Ensures text aligns left even when container is centered
        
        @include respond-below('sm') {
          font-size: 0.85rem;
          display: inline-block;
          width: calc(100% - 30px); // Account for icon width and margin
        }
      }
    }
  }

  &__image-container {
    position: relative;
    height: 100%;
    margin: 0;
    padding: 0;
    
    @include respond-below('sm') {
      height: 250px;
      order: 1; // Moves image to top only on mobile
      width: 100%;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 0;
    padding: 0;
  }

  &__cta {
    background-color: $white;
    color: $primary-color;
    padding: 0.75rem 1.5rem;
    font-weight: 600;
    border-radius: $border-radius;
    align-self: flex-start;
    
    @include respond-below('sm') {
      align-self: center;
    }
    
    &:hover {
      background-color: darken($white, 5%);
      transform: translateY(-2px);
    }
  }
}

// Services Section
.services {
  @include section-padding;
  background-color: #f8f9fa;

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.6s ease forwards;
    
    @include respond-below('md') {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 1.5rem;
    }
  }

  &__card {
    background: $white;
    padding: 2rem;
    border-radius: $border-radius;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    
    @include respond-below('sm') {
      padding: 1.5rem;
    }
    
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    }
  }

  &__card-title {
    color: $primary-color;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    position: relative;
    padding-bottom: 0.5rem;
    
    @include respond-below('sm') {
      font-size: 1.25rem;
    }
    
  }

  &__card:hover &__card-title::after {
    width: 60px;
  }

  &__card-description {
    color: $text-light;
    line-height: 1.6;
  }
}

// Features Section
.features {
  @include section-padding;
  background: $primary-color;
  padding-left: 4rem;
  padding-right: 4rem;
  
  @include respond-below('lg') {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  
  @include respond-below('sm') {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .section-title {
    color: $white;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3rem;
    opacity: 0;
    animation: fadeIn 0.8s ease forwards;
    
    @include respond-below('md') {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }

  &__item {
    text-align: center;
    padding: 2.5rem 2rem;
    position: relative;
    overflow: hidden;
    border: 2px solid rgba($white, 0.2);
    border-radius: $border-radius;
    transition: all 0.3s ease;
    
    @include respond-below('sm') {
      padding: 1.5rem 1rem;
    }
    
    &:hover {
      border-color: rgba($white, 0.4);
      box-shadow: 0 0 20px rgba($white, 0.1);
      transform: translateY(-5px);

      .features__icon-wrapper {
        transform: scale(1.1);
      }
      
      &::before {
        opacity: 1;
      }
    }
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: calc(#{$border-radius} - 2px);
      padding: 2px;
      background: linear-gradient(
        135deg,
        rgba($white, 0.2) 0%,
        rgba($white, 0.1) 50%,
        rgba($white, 0.2) 100%
      );
      mask: linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  &__icon-wrapper {
    margin-bottom: 1.5rem;
    transform: scale(1);
    transition: transform 0.3s ease;
    
    .features__icon {
      color: $white;
      width: 3rem;
      height: 3rem;
    }
  }

  &__title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: $white;
  }

  &__description {
    color: rgba($white, 0.9);
    line-height: 1.6;
  }
}

// Testimonials Section
.testimonials {
  @include section-padding;
  background-color: #f8f9fa;

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    max-width: 1000px;
    margin: 0 auto;
  }

  &__card {
    background: $white;
    border-radius: $border-radius;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    opacity: 0;
    animation: fadeIn 0.6s ease forwards;
    
    &:hover {
      transform: translateY(-5px);
    }
  }

  &__content {
    padding: 2rem;
    position: relative;
    
    &::before {
      content: '"';
      position: absolute;
      top: 0;
      left: 2rem;
      font-size: 4rem;
      color: rgba($primary-color, 0.1);
      font-family: serif;
      line-height: 1;
    }
  }

  &__text {
    font-style: italic;
    color: $text-dark;
    margin-bottom: 1.5rem;
    line-height: 1.6;
    position: relative;
    z-index: 1;
  }

  &__author {
    font-weight: 600;
    color: $primary-color;
    display: inline-block;
    position: relative;
    padding-left: 2rem;
    
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 1.5rem;
      height: 2px;
      background: $primary-color;
    }
  }
}

// CTA Section
.cta {
  @include section-padding;
  // background: linear-gradient(135deg, 
  //   darken($primary-color, 5%) 0%,
  //   $primary-color 100%
  // );
  position: relative;
  overflow: hidden;

  &__container {
    @include container;
    position: relative;
    z-index: 2;
  }

  &__content {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    padding: 4rem 2rem;
    background: rgba(255, 255, 255, 0.95);
    border-radius: $border-radius;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    animation: floatIn 0.8s ease-out;
    border: 1px solid rgba(255, 255, 255, 0.1);
    
    @include respond-below('md') {
      padding: 3rem 1.5rem;
    }
  }

  &__icon {
    width: 48px;
    height: 48px;
    color: $primary-color;
    margin-bottom: 1.5rem;
    animation: pulse 2s infinite;
    
    @include respond-below('sm') {
      width: 36px;
      height: 36px;
    }
  }

  &__title {
    font-size: 2.5rem;
    font-weight: 700;
    color: $text-dark;
    margin-bottom: 1rem;
    animation: slideInUp 0.6s ease-out;
    
    @include respond-below('md') {
      font-size: 2rem;
    }
    
    @include respond-below('sm') {
      font-size: 1.75rem;
    }
  }

  &__text {
    font-size: 1.2rem;
    color: $text-light;
    margin-bottom: 2rem;
    animation: slideInUp 0.8s ease-out;
    
    @include respond-below('md') {
      font-size: 1.1rem;
    }
  }

  &__button {
    display: inline-flex;
    align-items: center;
    gap: 0.75rem;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    font-weight: 600;
    color: $white;
    background: $primary-color;
    border: none;
    border-radius: $border-radius;
    cursor: pointer;
    transition: all 0.3s ease;
    animation: slideInUp 1s ease-out;
    user-select: none;  // Prevent text selection
    
    // Add focus styles for accessibility
    &:focus {
      outline: 2px solid rgba($white, 0.5);
      outline-offset: 2px;
    }

    // Add hover styles
    &:hover {
      transform: translateY(-2px);
      background: darken($primary-color, 10%);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);

      .cta__button-icon {
        transform: translateX(5px);
      }
    }

    // Add active styles
    &:active {
      transform: translateY(0);
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    }

    @include respond-below('sm') {
      padding: 0.875rem 1.5rem;
      font-size: 1rem;
    }

    &-icon {
      width: 20px;
      height: 20px;
      transition: transform 0.3s ease;
    }
  }
}

// FAQ Section
.faq {
  @include section-padding;
  background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);

  &__grid {
    max-width: 800px;
    margin: 0 auto;
    display: grid;
    gap: 1.5rem;
    
    @include respond-below('md') {
      padding: 0 1rem;
    }
  }

  &__card {
    background: $white;
    border-radius: $border-radius;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    opacity: 0;
    animation: fadeInUp 0.6s ease forwards;
    
    &:hover {
      transform: translateX(10px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
  }

  &__content {
    padding: 1.5rem;
  }

  &__question {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: $primary-color;
    position: relative;
    padding-left: 1.5rem;
    
    @include respond-below('sm') {
      font-size: 1.1rem;
    }
    
    &::before {
      content: '•';
      position: absolute;
      left: 0;
      color: $primary-color;
    }
  }

  &__answer {
    color: $text-light;
    line-height: 1.6;
    padding-left: 1.5rem;
  }
}

// Additional Animations
@keyframes floatIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

// Additional Media Query for very small devices
@media screen and (max-width: 320px) {
  .hero {
    &__title {
      font-size: 1.5rem;
    }
    
    &__subtitle {
      font-size: 1rem;
    }
    
    &__image-container {
      min-height: 200px;
    }
  }
  
  .section-title {
    font-size: 1rem;
  }
  
  .services__card,
  .testimonials__content,
  .faq__content {
    padding: 1rem;
  }
  
  .cta {
    &__content {
      padding: 2rem 1rem;
    }
    
    &__title {
      font-size: 1.5rem;
    }
    
    &__text {
      font-size: 1rem;
    }
  }
}
.footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .footer-nav {
    margin-bottom: 20px;

    a {
      color: #fff;
      text-decoration: none;
      margin: 0 10px;
      font-size: 16px;
      transition: color 0.3s;

      &:hover {
        color: #bbb;
      }
    }
  }

  .social-icons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    a {
      color: #fff;
      margin: 0 10px;
      transition: color 0.3s, filter 0.3s;

      svg {
        width: 24px;
        height: 24px;
      }

      &:hover {
        filter: drop-shadow(0 0 8px currentColor);
      }

      &.instagram:hover {
        color: #E1306C;
      }

      &.facebook:hover {
        color: #1877F2;
      }

      &.youtube:hover {
        color: #FF0000;
      }

      &.linkedin:hover {
        color: #0A66C2;
      }
    }
  }

  .copyright {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    .footer-nav a {
      font-size: 14px;
    }

    .social-icons a svg {
      width: 20px;
      height: 20px;
    }

    .copyright {
      font-size: 12px;
    }
  }
}